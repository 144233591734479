@import "Variables";

.image-container {
  text-align: center;
  h2 {
    padding-top: 1rem;
    font-size: 14px;
    font-weight: bold;
  }

  img{
    max-width: 100%;
  }
}

.image-container.solo{
  padding-bottom: 1rem;
}