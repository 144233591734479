.scroll-text-container {
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  width: 100%;
}

.scroll-text {
  animation-fill-mode: forwards;
}

#rssBlock{
  left:0;
  width: 100%;
  overflow: hidden;
}

/*remove p*/
.cnnContents {

  margin:0 auto;
  white-space: nowrap;
  font-weight: 300;
}

.cnnContents-sm {
  margin:0 auto;
  white-space: nowrap;
  font-weight: 300;
}

.marqueeStyle {
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left1 30s linear infinite;
  animation: scrolling-left1 30s linear infinite;
}

.marqueeStyle2 {
  display:inline-block;
  /* Apply animation to this element */
  -webkit-animation: scrolling-left2 30s linear infinite;
  animation: scrolling-left2 30s linear infinite;
  animation-delay: 15s;
}
/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
  0% {transform: translateX(50%);
    -webkit-transform: translateX(50%);}
  100% {transform: translateX(-150%);
    -webkit-transform: translateX(-150%);}
}
@keyframes scrolling-left2 {
  0% {transform: translateX(-50%);
    -webkit-transform: translateX(-50%);}
  100% {transform: translateX(-250%);
    -webkit-transform: translateX(-250%);}
}

@-webkit-keyframes scrolling-left1 {
  0% {-webkit-transform: translateX(50%);}
  100% {-webkit-transform: translateX(-150%);}
}
@-webkit-keyframes scrolling-left2 {
  0% {-webkit-transform: translateX(-50%);}
  100% {-webkit-transform: translateX(-250%);}
}