@import "Variables";

.auth-container {
  background-color: $color-white-box;
}

.auth-title {
  margin: 2rem 0 1rem 0!important;
}

.auth-input {
  margin: 0.5rem 0!important;
}

.stay-log-checkbox {
  margin-right: 0.5rem!important;
}

.forget-password {
  color: #6d6d6d;
  text-decoration: underline;

}

.forget-password:hover {
  color: $color-green;
}

.auth-button {
  margin: 0.5rem 0!important;
}

.auth-register {
  margin: 0.5rem 0 2rem 0!important;
  a {
    color: $color-green;
  }
}
