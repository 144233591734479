@import "../../assets/Variables.scss";

@keyframes spin3D {
  from { transform: rotateY(0deg) }
  to { transform: rotateY(360deg) }
}
.spinner-image {
  height: 100px;
  animation-name: spin3D;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: ease-in-out;
}