@import "./../../../assets/Variables";

.dzu-dropzone {
  border: 1px dashed #d9d9d9!important;
  background-color: white;
  overflow: hidden!important;
  label {
    color: $color-green!important;
  }
}

.remove {
  font-size: 18px!important;
  margin-right: 5px;
}

.remove:hover {
  color: $color-green!important;
  cursor: pointer;
}