@import "../../assets/Variables.scss";

.eventPopup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 11;
}

.eventContainer {
  display: table;
  background-color: white;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.eventImgContainer {
  max-width: 90vw;
  max-height: 90vh;
}

.eventImg {
  max-width: 90vw;
  max-height: 80vh;
}

.eventActionContainer {
  padding: 0.5rem 1rem;
}

.eventButton {
  color: green;
}

.rightContainer {
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 25px;
}

.partnerTitle {
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.lastMatchTitle {
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.actionButton {
  border: none;
  background-color: rgba(255, 255, 128, 0);
}

.lastMatch_sm {
  position: fixed;
  top: 90px;
  left: 0;
  padding: 0.5rem;
  width: 100%;
  height: 40px;
  background-color: black;
  color: white;
  margin-bottom: 1rem;
  z-index: 10;
}

.lastMatchText {
  font-size: 1rem;
  width: 101%;
}
