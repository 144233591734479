@import "../../assets/Variables";

.article-container {
  padding: 1rem;
}

.title {
  font-weight: bold;
  margin-bottom: 2rem;
}

.article {
  font-size: 1rem;
}

.date {
  font-weight: bold;
}