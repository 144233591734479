@import "Variables";

@media screen and (min-width: 1024px){
  .opposition-team {
    width: 75%;
    height: 100%;
    text-align: center;
    div {
      display: inline-block;
    }

    span {
      font-size: 20px;
    }

    img {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .team-logo {
    height: 75%;
  }

  .match-info {
    margin-left: 1rem;
    padding-left: 0!important;
    padding-right: 0!important;
    height: 50%;
    width: 25%;
    span {
      display: block;
    }
  }
}

@media screen and (min-width: 850px) and (max-width: 1023px){
  .opposition-team {
    width: 75%;
    height: 100%;
    text-align: center;
    div {
      display: inline-block;
    }

    span {
      font-size: 15px;
    }

    img {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .team-logo {
    height: 50%;
  }

  .match-info {
    margin-left: 1rem;
    height: 50%;
    width: 25%;
    font-size: 15px;
    span {
      display: block;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 849px){
  .opposition-team {
    width: 75%;
    height: 100%;
    text-align: center;
    div {
      display: inline-block;
    }

    span {
      font-size: 12px;
    }

    img {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .team-logo {
    height: 50%;
  }

  .match-info {
    margin-left: 1rem;
    height: 50%;
    width: 25%;
    font-size: 12px;
    span {
      display: block;
    }
  }
}

@media screen and (min-width: 620px) and (max-width: 767px){
  .opposition-team {
    width: 75%;
    height: 100%;
    text-align: center;
    div {
      display: inline-block;
    }

    span {
      font-size: 12px;
    }

    img {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .team-logo {
    height: 50%;
  }

  .match-info {
    margin-left: 1rem;
    height: 50%;
    width: 25%;
    font-size: 12px;
    span {
      display: block;
    }
  }
}

@media screen and (min-width: 470px) and (max-width: 619px){
  .match-container {
    display: block!important;
    width: 100%;
    height: 200px!important;
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 1px $color-grey-border;
    overflow: hidden;
  }

  .match-info {
    text-align: center;
    margin-left: 1rem;
    margin-top: 1rem;

    height: 20%;
    width: 100%;
    font-size: 18px;
    span {
      display: block;
    }
  }

  .team-logo {
    height: 50%;
  }

  .left-part {
    float: left;
    background: $color-green;
    width: 10%!important;
    height: 100%;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: skewX(-10.5deg);
    -ms-transform: skewX(-10.5deg);
    -o-transform: skewX(-10.5deg);
    transform: skewX(-10.5deg);
    transform-origin: top right;
  }


  .opposition-team {
    width: 110%;
    height: 50%;
    text-align: center;
    margin-top: 3rem;
    div {
display: inline-block;
    }

    span {
      font-size: 12px;
    }
  }

  .team-logo-1 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 50%;

  }

  .team-logo-2 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 50%;
  }

  .team-1 {
    width: 30%!important;
    height: 100%;
  }

  .result {
    width: 10%;
  }

  .team-2 {
    width: 30%!important;
    height: 100%;
  }
}

@media screen and (max-width: 469px){
  .match-container {
    display: block!important;
    width: 100%;
    height: 200px!important;
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 1px $color-grey-border;
    overflow: hidden;
  }

  .match-info {
    background: url(http://145.239.86.157:8080/20-08-2021_05-46-02_Logo_A.%20S.%20Celtic%20de%20lHyeres%20Locarn.jpg);
    text-align: center;
    margin-left: 1rem;
    margin-top: 1rem;

    height: 20%;
    width: 100%;
    font-size: 15px;
    span {
      display: block;
    }
  }

  .team-logo {
    height: 50%;
  }

  .left-part {
    float: left;
    background: $color-green;
    width: 10%!important;
    height: 100%;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: skewX(-10.5deg);
    -ms-transform: skewX(-10.5deg);
    -o-transform: skewX(-10.5deg);
    transform: skewX(-10.5deg);
    transform-origin: top right;
  }


  .opposition-team {
    width: 110%;
    height: 50%;
    text-align: center;
    margin-top: 3rem;
    div {
      display: inline-block;
    }

    span {
      font-size: 10px;
    }

  }

  .team-logo-1 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 50%;

  }

  .team-logo-2 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 50%;
  }

  .team-1 {
    text-align: center;
    width: 30%!important;
    height: 100%;
  }

  .result {
    width: 10%;
  }

  .team-2 {
    width: 30%!important;
    height: 100%;
  }
}

.calendar-top-container {
  height: 60px;
  width: 100%;
}

.select-saison {
  float: right;
  height: 100%;
}

.match-container {
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 1px $color-grey-border;
  overflow: hidden;
}

.month-date {
  border-bottom: black solid 2px;
  font-size: 30px;
  font-weight: normal;
  margin: 0;
  span {
    font-weight: bold;
    color: $color-green;
  }
}

.left-part {
  background: $color-green;
  width: 5%;
  height: 100%;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: skewX(-10.5deg);
  -ms-transform: skewX(-10.5deg);
  -o-transform: skewX(-10.5deg);
  transform: skewX(-10.5deg);
  transform-origin: top right;
}

.league-info {
  margin-top: 10px;
}

.team-1 {
  width: 45%;
  height: 100%;

}

.result {
  width: 10%;

  input {
    width: 40%;
  }
}

.team-2 {
  width: 45%;
  height: 100%;
}

.addArticle {
  position: fixed;
  bottom: 10px;
  right: 10px;
  align-items: center;
  display: block;

  button {
    background-color: transparent;
    border: none;
  }
}
.addArticle span {
  cursor: pointer;
}

.addArticle i {
  color: $color-green;
  font-size: 60px;
}

.cancel-edit {
  position: relative!important;
  right: 0!important;
  color: red;
  float: right;
}

.cancel-edit:hover {
  opacity: 0.8;
  color: black;
}

.remove-article {
  position: relative!important;
  right: 0!important;
  color: $color-green;
  float: right;
}

.remove-article:hover {
  opacity: 0.8;
  color: black;
}

.changeDate-container {
  margin: auto;
}

.dateTime {
  margin-top: 2rem;

  .date-picker {
    margin: auto;
  }

  .DayPicker-Day--selected {
    background-color: $color-green!important;
  }
}

.action-item {
  padding: 0.5rem;
  cursor: pointer;
}

.separator {
  height: 1px;
  background-color: $color-grey-border;
}