@import "./../../assets/Variables";

.custom-scrollbars__container {
    overflow: hidden;
    position: relative;
  }
  
  .custom-scrollbars__content {
    -ms-overflow-style: none;
    overflow: auto;
    scrollbar-width: none;
    height: 100vh;
  }

  .custom-scrollbars__scrollbar {
      height: 100vh;
  }
  
  .custom-scrollbars__content::-webkit-scrollbar {
    display: none;
  }
  
  .custom-scrollbars__track-and-thumb {
    display: block;
    height: 100vh;
    position: relative;
    width: 10px;
  }
  
  .custom-scrollbars__track {
    background-color: #fcfcfc;
    border-radius: 12px;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 10px;
  }
  
  .custom-scrollbars__thumb {
    border-radius: 12px;
    height: 100vh;
    background-color: $color-green;
    position: absolute;
    width: 10px;
  }
  