@import "Variables";

.register-container {
  background-color: $color-white-box;
}

.register-title {
  margin: 2rem 0 1rem 0!important;
}

.register-input-row {
  margin: 0 1rem 0 1rem!important;
}

.register-input {
  margin: 0.5rem 0!important;
}

.register-button {
  margin: 0.5rem 0!important;
}

.auth-register {
  text-align: center;
  margin: 0.5rem 0 2rem 0!important;
  a {
    color: $color-green;
  }
}

.password-requirement-container {
  padding: 0!important;
}

.password-requirement-title {
  font-size: 15px;
  color: #878787;
  list-style-type: none;
}

.password-requirement-item-valid {
  font-size: 15px;
  color: $color-green;
  list-style-type: none;
}

.password-requirement-item-invalid {
  font-size: 15px;
  color: $color-invalid;
  list-style-type: none;
}

.check-container {
  margin-right: 0.5rem;
}

.check-invalid {
  stroke: $color-invalid;
}

.check-valid {
  stroke: $color-green;
}

.MuiFormHelperText-root {
  color: $color-invalid!important;
  font-size: 12px!important;
}

.radio-notificationMail {
  color: $color-green;
}