@import "Variables";

.monProno-container {
  min-height: 100%!important;
}

.monProno-nav-item {
  border: black 1px solid;
  padding: 15px;
  margin-right: 1rem!important;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}

.active {
  background-color: black;
  color: white;
}

.monProno-nav-item:hover {
  color: white;
  background-color: black;
}

.monProno-classement-table {
  background-color: $color-white-box;
  font-weight: bold;
  margin-top: 1rem;

  img {
    margin-right: 10px;
  }

  .monProno-classement-header {
    background-color: black;
    color: white;
    border-bottom: white 3px solid;
    height: 60px;
    text-align: center;
  }

  .rank-row {
    cursor: pointer;
    text-align: center;
    border: solid 1px $color-grey-border;
  }

  .classement-user {
    background-color: $color-green;
    color: white;
  }

  .rank-nb {
    font-size: 20px;
  }

  .classement-user {
    .rank-evo-win {
      font-size: 12px!important;
      color: white !important;
    }
  }

  .rank-evo-win {
    font-size: 12px!important;
    color: black!important;
  }


  .rank-evo-win {
    font-size: 12px!important;
    color: $color-green!important;
  }

  .rank-evo-loose {
    font-size: 12px;
    color: $color-invalid!important;
  }
}

.nextMatch-title {
  margin-top: 0.5rem!important;
  border-bottom: black solid 2px!important;
}

.nextMatch-container {
  font-size: 30px;
  font-weight: normal;
  margin: 0;
  span {
    font-weight: bold;
    color: $color-green;
  }
}

.prono-input {
  margin: 0 0.5rem!important;
}

.left-part {
  width: 50px;
  height: 125px;
  background-color: $color-green;
  -webkit-transform: skewX(-10.5deg);
  -ms-transform: skewX(-10.5deg);
  -o-transform: skewX(-10.5deg);
  transform: skewX(-10.5deg);
  transform-origin: top right;
}

.valid-prono {
  color: $color-green!important;
  font-size: 3rem;
}

.cancel-prono {
  color: $color-invalid!important;
  font-size: 3rem;
}

.MuiLinearProgress-bar {
  background-color: $color-green!important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #00000040!important;
}

.link-accept-rule{
  color: $color-green!important;
}

.link-accept-rule:hover {
  text-decoration: underline!important;
  text-underline: $color-green!important;
}