@import "Variables";

.green-band {
  position: absolute;
  left: 0;
  top: 0;
  height: 50%;
  width: 100%;
}

.nav-top {
  font-weight: 700;
  color: white;
  z-index: 1;
  height: 100%;
}

.white-band-left {
  border-left: solid black 5px;
  border-right: solid black 5px;
  width: 50px;
  transform: skewX(45.5deg);
}

.white-band-right {
  border-left: solid black 5px;
  border-right: solid black 5px;
  width: 50px;
  transform: skewX(-45.5deg);
}

.white-band-left.sm, .white-band-right.sm {
  height: 75px;
}

.white-band-left.md, .white-band-right.md {
  height: 125px;
}

.nav-logo {
  float: left;
  position: relative;
  top: 25%;
  @media screen and (min-width: 961px) {
    height: 100px;
  }
  height: 60px;
  cursor: pointer;
  z-index: 1;
}

@media screen and (min-width: 1440px) {
  .dropdown-menu.right {
    position: fixed;
    right: 13%;
    left: auto;
    top: $navigation-height-large/2;
    background-color: white;
    border: black 1px solid;
    border-radius: 0.25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .dropdown-menu.right {
    position: fixed;
    right: 8%;
    left: auto;
    top: $navigation-height-medium/2;
    background-color: white;
    border: black 1px solid;
    border-radius: 0.25rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dropdown-menu.right {
    position: fixed;
    right: 8%;
    left: auto;
    top: $navigation-height-medium/2;
    background-color: white;
    border: black 1px solid;
    border-radius: 0.25rem;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .dropdown-menu.right {
    position: fixed;
    right: 8%;
    left: auto;
    top: $navigation-height-small/2;
    background-color: white;
    border: black 1px solid;
    border-radius: 0.25rem;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .dropdown-menu.right {
    position: fixed;
    right: 8%;
    left: auto;
    top: $navigation-height-small/2;
    background-color: white;
    border: black 1px solid;
    border-radius: 0.25rem;
  }
}

@media screen and (max-width: 374px) {
  .dropdown-menu.right {
    position: fixed;
    right: 8%;
    left: auto;
    top: $navigation-height-small/2;
    background-color: white;
    border: black 1px solid;
    border-radius: 0.25rem;
  }
}


.button-menu {
  position: relative;
  top: 75%;
  display: flex;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  width: 40px;
  height: 26px;
  float: left;
  margin-left: 10px;
  margin-right: 10px;

  .hamburger {
    width: 100%;
  }
}

.login-nav {
  text-decoration: none!important;
  color: white!important;
  z-index: 10;
}

.login-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 1rem;
}

.item-menu {
  text-decoration: none!important;
}

.item-menu:hover {
  color: white!important;
  background-color: $color-green!important;
}

.nav-item-active {
  color: $color-green!important;
}