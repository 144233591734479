@import "Variables";

.left-navigation {
  position: fixed;
  top: $navigation-height-small;
  left: -$navigation-left-width;
  height: 100%;
  width: $navigation-left-width;
  background-color: #048f00;
  z-index: 10;
  padding-top: 2rem;
}

.left-navigation.show {
  left: 0;
  z-index:10;
  animation-duration: 1s;
  animation-name: slideShowMenu;
}

.left-navigation.hide {
  left: -$navigation-left-width;
  animation-duration: 1s;
  animation-name: slideHideMenu;
}

@keyframes slideHideMenu {
  from {
    left: 0;
  }
  to {
    left: -$navigation-left-width;
  }
}

@keyframes slideShowMenu {
  from {
    left: -$navigation-left-width;
  }
  to {
    left: 0;
  }
}

.nav-box-left {
  width: 100%;
  height: 100%;
  padding-top: 1rem;
}

.box-item-left {
  text-align: center;
  width: 100%;
  height: 50px;
}

.dropDown-item-container {
  text-align: center;
  width: 100%;
  height: 35px;
}

@media screen and (min-width: 425px) {
  .nav-item-left {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    top: calc(50% - 11px);
  }

  .dropDown-item-nav-level1 {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    top: calc(50% - 11px);
  }

  .dropDown-item-nav-level2 {
    text-decoration: none;
    color: white;
    font-size: 15px;
    position: relative;
    top: calc(50% - 11px);
  }
}

@media screen and (max-width: 424px) {
  .nav-item-left {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 15px;
    position: relative;
    top: calc(50% - 11px);
  }

  .dropDown-item-nav-level1 {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 15px;
    position: relative;
    top: calc(50% - 11px);
  }

  .dropDown-item-nav-level2 {
    text-decoration: none;
    color: white;
    font-size: 10px;
    position: relative;
    top: calc(50% - 11px);
  }
}

.dropDown-item-nav-level1.active {
  color: black;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  top: calc(50% - 11px);
}

.dropDownCalendar {
  width: 100%;
  background-color: #04af00;
}

a {
  text-decoration: none;
  color: white;
}