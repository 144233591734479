@import "../../assets/Variables.scss";


.modal-body {
    height: auto;
    margin: 4% 2%;
}

.max-modal-height {
    max-height: 800px;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place front is invalid - may break your css so removed */
    overflow: auto; /* Enable scroll if needed */
    background-color: #0006;
}

.btn-primary {
    background-color: $color-green;
}

.btn-primary:hover {
    opacity: 0.8;
    background-color: $color-green;
}

.btn-secondary {
    background-color: black;
}