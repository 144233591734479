@import "Variables";

.profile-container {
  background-color: $color-white-box;
}

.profile-title {
  padding: 1rem!important;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $color-grey-border;
}

.profile-input {
  margin: 0.5rem 0!important;
}

.profile-button {
  margin: 0.5rem 0!important;
}

.profile-myInformation {
  margin: 1rem 0!important;
}

.change-password-container {
  border-top: 1px solid $color-grey-border;
  border-bottom: 1px solid $color-grey-border;
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.btn-change-password {
  color: $color-green;
  font-weight: bold;
  cursor: pointer;
}

.delete-user {
  padding-left: 1rem!important;
}

.delete-user-icon {
  color: $color-invalid;
}