@import "Variables";

.addUpdateRank {
  position: fixed;
  bottom: 75px;
  right: 0;
  align-items: center;
  display: block;

  button {
    background-color: transparent;
    border: none;
  }

  i {
    font-size: 60px;
    color: $color-green;
  }
}

.addRank {
  position: fixed;
  bottom: 75px;
  right: 60px;
  align-items: center;
  display: block;

  button {
    background-color: transparent;
    border: none;
  }

  i {
    font-size: 60px;
    color: $color-green;
  }
}

.deleteRank {
  position: fixed;
  bottom: 150px;
  right: 0;
  align-items: center;
  display: block;

  button {
    background-color: transparent;
    border: none;
  }

  i {
    font-size: 60px;
    color: $color-green;
  }
}

.edit-player, .remove-player {
  color: $color-green;
}

.cancel-edit {
  color: $color-invalid;
}

@media screen and (min-width: 521px){
  .classement-top-container {
    height: 60px;
    width: 100%;

    .nav-item {
      margin-right: 1rem;
    }

    .select-saison {
      float: right;
      display: inline-block;
      height: 100%;
    }

    span {
      color: black;
      padding: 0 15px;
      display: inline-block;
      height: 58px;
      line-height: 60px;
      border: 1px black solid;
      font-size: 13px;
      font-weight: bold;
    }

    .active{
      background-color: black;
      color: white;
    }

    span:hover {
      color: white;
      background-color: black;
      cursor: pointer;
    }
  }

}

@media screen and (max-width: 520px){
  .classement-top-container {
    height: 60px;
    width: 100%;

    .nav-item {
      margin-right: 1rem;
    }

    .select-saison {
      float: right;
      display: inline-block;
      height: 100%;
    }

    span {
      color: black;
      padding: 0 15px;
      display: inline-block;
      height: 58px;
      line-height: 60px;
      border: 1px black solid;
      font-size: 10px;
      font-weight: bold;
    }

    .active{
      background-color: black;
      color: white;
    }

    span:hover {
      color: white;
      background-color: black;
      cursor: pointer;
    }
  }

  .classement-table {
    width: 100%;
    background-color: $color-white-box;
    font-weight: bold;
    border-spacing: 0;
    margin-top: 1rem;

    td {
      height: 40px;
      line-height: 40px;
      border-bottom: $color-grey-border 2px solid;
      font-size: 12px;
    }
  }

}

.classement-container {
  min-width: 380px;
}

.dateUpdate {
  color: $color-green;
  font-weight: bold;
}

.classement-table {
  width: 100%;
  background-color: $color-white-box;
  font-weight: bold;
  border-spacing: 0;
  margin-top: 1rem;
  td {
    height: 40px;
    line-height: 40px;
    border-bottom: $color-grey-border 2px solid;
  }

  img {
    margin-right: 10px;
  }

  .classement-header
  {
    background-color: black;
    color: white;
    td {
      border-bottom: white 3px solid;
      height: 60px;
    }
  }

  .classement-usmb {
    background-color: $color-green;
    color: white;
  }

  .number-col {
    text-align: center;
    width: 6%;
  }

  .rank {
    text-align: center;
    width: 9%;
  }
}