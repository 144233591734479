@import "Variables";

html {
  overflow: hidden;
}

a:hover {
  text-decoration: none!important;
}

.page-container {
  min-width: 320px;
}

.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
}

.header.sm {
  height: 75px;
}

.header.md {
  height: 125px;
}

.body.sm {
  max-width: 90%;
  margin-top: 75px!important;
  min-height: calc(100vh - 150px);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.body.md {
  width: 100%;
  margin-top: 125px!important;
  min-height: calc(100vh - 200px);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer {
  width: 100%;
  height: 75px;
}

.background-window.show {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.date-picker {
  margin: auto;
}

.DayPicker-Day--selected {
  background-color: $color-green!important;
}

@media screen and (max-width: 960px) {
  .notification-container {
    top: 75px;
  }
}

@media screen and (min-width: 961px) {
  .notification-container {
    top: 125px;
  }
}
