//////////////////////////////////////
//
// css variables to use throughout the project
//
/////////////////////////////////////

//////////////////////////////////////
//
// Start general appearance
@import "~react-image-gallery/styles/scss/image-gallery.scss";

$navigation-height-large: 150px;
$navigation-height-medium: 100px;
$navigation-height-small: 75px;

$navigation-left-width: 75%;

$footer-height: 75px;

.pt-1 {
  padding-top: 1rem;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.centerY {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// End general appearance
//
/////////////////////////////////////


//////////////////////////////////////
//
// Start color

$color-green: #048f00;
$color-white-box: #d6d6d6;
$color-grey-border: #c7c7c7;
$color-invalid: #dc3545;
$color-valid: #28a745;

// End color
//
/////////////////////////////////////
.horizontal-top-line-grey-dark{
  border-top: 1px solid #E3E3E3;
}
