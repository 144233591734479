@import "Variables";

.remove-category, .update-category {
  position: relative!important;
  color: $color-green;
}

.category-link {
  color: $color-green;
  text-decoration: $color-green;
}