@import "../../../assets/Variables";

.timeInput-container {
    display: flex;
    text-align: center;
    width: 100%;

    input {
        background-color: #e3eff3;
        border: none!important;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2) !important;
        text-align: center;
    }

    .hour {
        margin-right: 1rem;
    }

    .timeInput-content {
        text-align: center;
        display: flex;
        width: 75%;
        margin: auto;

        span {
            height: fit-content;
            margin: auto;
            font-weight: bold;
        }

        i {
            color: $color-green;
            font-size: 4rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            cursor: pointer;
        }
    }
}