.selected {
  border: #048f00 2px solid;
  position: relative;

  .span-order {
    text-align: center;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
    background-color: #048f00;
    border-radius: 50%;
  }
}

.rdp-day_selected {
  background-color: #048f00!important;
}