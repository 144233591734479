@import "Variables";

.MuiInputLabel-root.Mui-focused {
   color: $color-green!important;
}

.MuiFilledInput-root {
  background-color: white!important;
}

:after {
  border-bottom: 2px solid $color-green!important;
}

.contactIcon {
  color: $color-green!important;
  margin-right: 5px;
}

.MuiFormHelperText-root {
  color: red!important;
}